<article class="carousel-container">

    <section *ngFor="let item of items" class="carousel-card">
      <img [src]="item.imageUrl" />
      <div class="util-padvert-5 util-padhori-10">
        <p class="title">{{item.title}}</p>
        <p class="subtitle">{{item.subTitle}}</p>
      </div>
      <div class="util-padall-10">
          <button class="button"  *ngFor="let button of item.buttons" (click)="doAction(button)">{{button.name}}</button>
      </div>
    </section>

</article>
