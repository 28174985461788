<article class="l-container-modal">
  <div class="l-container-modal-backdrop" (click)="active()"> 

  </div>
  <div class="l-container-modal-dialog">
      <header class="l-container-modal-header">
        <img src="/assets/img/close.svg" height="16"  (click)="active()" />
      </header>
      <img class="l-container-modal-image" src="{{urlImage}}" />
  </div>
</article>