<main class="l-container-main-image"> 

  <div *ngIf="localConfigService.configAll" class="l-row-left">
      <div class="l-column">
          
          <label>Choose a bot to start the test </label>

          <select class="select" (change)="showbot()" [(ngModel)]="currentBot">
            <option value="-1">-- Choose a bot .. --</option>
            <option *ngFor="let item of localConfigService.configAll; let i = index;" [value]="i">{{item.client_name}}</option>
          </select>

      </div>
  </div>


  <div class="boxChatBubble" *ngIf="!chatOpen"  (click)="openChat({data: {type : 'chatbubble', state: true}})" >
      <img  class="boxChatBubble--icon" src="/assets/img/chat.svg" />
  </div>

  <a  class="boxChatPopup" *ngIf="chatOpen">
     <iframe #boxChatFrame id="boxChatFrame"  [src]="iframeUrl" height="100%" width="100%"></iframe>
  </a>

  
</main>