<header class="header" *ngIf="localConfigService.config">
    <div class="l-row  util-fullheight" *ngIf="headerType == HEADER_TYPE.CHAT">
        <div class="util-fullheight">
            <img class="header--avatar"
                src="{{localConfigService.config.avatar}}" />
        </div>
        <div class="header--botInfo">
            <h1 class="header--botName">{{localConfigService.config.bot_name}}</h1>
            <p *ngIf="uiService.conected && !uiService.writing" class="header--botStatus is-online">Online</p>
            <p *ngIf="uiService.conected && uiService.writing" class="header--botStatus is-online">{{localConfigService.config.typing}}</p>
            <p *ngIf="!uiService.conected" class="header--botStatus is-offline">{{localConfigService.config.offline}}</p>
        </div>

        <img class="header--close" (click)="eventEndChat()" src="/assets/img/close.svg" />
    </div>


    <div class="l-row util-selfcenter" *ngIf="headerType == HEADER_TYPE.FEEDBACK_FORM">
        <h1 class="header--tile">{{localConfigService.config.before_you_go}}</h1>  
        <img class="header--close" (click)="eventCloseChat()" src="/assets/img/close.svg" />
    </div>


    <div class="l-row" *ngIf="headerType == HEADER_TYPE.CUSTOMER_FORM">
        <h1 class="header--tile">{{localConfigService.config.bot_name}}</h1>  
        <img class="header--close" (click)="eventCloseChat()" src="/assets/img/close.svg" />
    </div>




    
</header>

<div class="header--shadow"></div>






