import { Component } from '@angular/core';
//import { RemoteConfigService } from './chat/services/remote-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Everis Chatbot';

  //constructor(public remoteConfigService: RemoteConfigService){
  constructor(){

  }
}
