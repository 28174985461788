/**
 * This file replace the remote-config.service.ts file to operate the frontend without Firebase
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { botslist } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LocalConfigService {

  appcode = '';
  attemptCount = 0;
  config: any = null;
  configAll: any = null;

  constructor(
    public router: Router,
    public utilsService: UtilsService,
  ) {
    this.appcode = utilsService.getParamValueQueryString('code');
    this.syncParameters();
  }

  syncParameters(): object {

    if(this.config !== null){
      return this.config;
    }

    if (Object.keys(botslist).length !== 0) {
      this.setConfigAll(botslist);
      if(this.appcode !== ''){
        this.setCurrentConfig(botslist[this.appcode]);
      }
      return this.config;
    }
    else {
      console.log('botlist sin datos en environment.ts');
    }

  }

  setCurrentConfig(config: any){
    this.config = config;
    this.utilsService.changeFavicon(this.config.favicon);
    this.setCSSProperties();
  }

  setConfigAll(objeto){
      this.configAll = []
      for(var property in objeto){
          let object = objeto[property];
          object.code = property;
          this.configAll.push(object);
      }
      console.log('configAll', this.configAll)
  }

  setCSSProperties(){
    console.log('Current primary color', this.config.primary_color);
    let root = document.getElementsByTagName('body')[0];
    root.style.setProperty('--primaryColor', this.config.primary_color);
    root.style.setProperty('--secondaryColor', this.config.secondary_color);

  }

}
