<app-modal-image *ngIf="showModalImage" (action)="closeModal($event)" [backgroundWhite]="png" [urlImage]="image"></app-modal-image>

<app-header [headerType]="HEADER_TYPE.CHAT"></app-header>

<main class="l-container-main" >
  <div class="boxMessageList">

    <div #boxMessageListscroller class="boxMessageList--scroller">
      <div  *ngFor="let chatMessage of chatService.chatMessages; index as i">
        
        <!--For simple messages or messages with buttons-->
        <article  *ngIf="chatMessage.type == CHATTYPE.SIMPLETEXT || chatMessage.type == CHATTYPE.OPTIONS || chatMessage.type == CHATTYPE.TECHNICALTEXT" [@newMessage]
        [className]="chatMessage.bot ? 'boxMessage boxMessage-left' : 'boxMessage boxMessage-right'">
          <div class="boxMessage--bubble" >
            <span *ngIf="chatMessage.message" [innerHTML]="chatMessage.message"></span>
            <div  *ngIf="chatMessage.buttons" class="buttonTray util-martop-10">
              <button *ngFor="let item of chatMessage.buttons" class="buttonOutlined buttonOutlined-primary" (click)="pushMessage(CHATTYPE.SIMPLETEXT, chatMessage, item.name)">{{item.name}}</button>
            </div>
          </div>
          <div class="boxMessage--arrow"></div>
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>

        <!--For simple messages with Technical Text (Options Form)-->
        <article *ngIf="chatMessage.type == CHATTYPE.OPTIONSFORM && chatMessage.message" [@newMessage]
        [className]="chatMessage.bot ? 'boxMessage boxMessage-left' : 'boxMessage boxMessage-right'">
          <div class="boxMessage--bubble">
            <span *ngIf="chatMessage.message" [innerHTML]="chatMessage.message"></span>
          </div>
          <div class="boxMessage--arrow"></div>
          <div *ngIf="chatMessage.optionsForm.options" class="btn-group-vertical boxMessage--options">
            <div *ngFor="let item of chatMessage.optionsForm.options" class="row justify-content-end buttonTray">
              <button #optionsFormButton [id]="chatMessage.optionsForm.code+'_'+item.code+'_'+i"  class="buttonOutlined buttonOutlined-primary buttonOption buttonOption2" (mouseover)="activeButtonMouseOver($event, 'button')" (mouseout)="deactiveButtonMouseOut($event, 'button')" (click)="pushOption(chatMessage.optionsForm, item, i)">{{item.text}}</button>
              <input #optionsFormInput type="text" *ngIf="item.additionalText" [id]="chatMessage.optionsForm.code+'_'+item.code+'_'+i+'_TEXT'" class="inputOption" [placeholder]="item.question ? item.question: ''">
            </div>
          </div>
          <div class="boxMessage--confirm">
            <div class="text-center">
              <hr class="hrOption">
              <button #optionsFormButtonConfirm [id]="chatMessage.optionsForm.code+'_'+i+'_CONFIRM'" class="buttonOutlined buttonOutlined-primary buttonConfirm" (mouseover)="activeButtonMouseOver($event, 'confirm')" (mouseout)="deactiveButtonMouseOut($event, 'confirm')"(click)="sendOptions(chatMessage.optionsForm, i)">{{chatMessage.optionsForm.button}}</button>
            </div>
          </div>
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>

        <!--For carousels messages-->
        <article  *ngIf="chatMessage.type == CHATTYPE.CAROUSEL" class="boxMessage boxMessage-carousel">
          <app-carousel [items]="chatMessage.caruselItems"></app-carousel>
          <!-- <div class="boxMessage--arrow"></div> -->
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>


        <!-- For images-->
        <article  *ngIf="chatMessage.type == CHATTYPE.IMAGE" class="boxMessage boxMessage-image" (click)="showModal(chatMessage.image, chatMessage.image)">
          <div class="boxMessage--bubble">
            <img class="boxMessage--photo" src="{{chatMessage.image}}">
          </div>
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>


        <!-- For video-->
        <article  *ngIf="chatMessage.type == CHATTYPE.VIDEO" class="boxMessage boxMessage-video">
          <div class="boxMessage--bubble">
            <iframe width="100%" [src]="chatMessage.video | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <!-- <div class="boxMessage--arrow"></div> -->
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>
  

        <!-- For audio-->
        <div *ngIf="chatMessage.type == CHATTYPE.AUDIO" class="chat-audio">
          
          <figure>
            <audio
                controls
                
                [src]="chatMessage.audio | safe">
                    Your browser does not support the
                    <code>audio</code> element.
            </audio>
        </figure>
        </div>


        <!-- For files -->
        <article  *ngIf="chatMessage.type == CHATTYPE.FILE" class="boxMessage boxMessage-file">
          <div class="boxMessage--bubble">

            <div class="l-row">
                <span class="boxMessage--filename" (click)="download_file(chatMessage.attachment, chatMessage.label)">{{chatMessage.label}}</span>
                
                <img class="boxMessage--fileicon util-marleft-20" (click)="download_file(chatMessage.attachment, chatMessage.label)" height="20" src="/assets/img/download.svg" />
            </div>

          </div>
          <div class="boxMessage--arrow"></div>
          <span class="boxMessage--time">{{chatMessage.time}}</span>
        </article>

     


       
    </div>
    </div>
    <div class="boxMessageList--shadow"></div>
  </div>
  <app-chat-input-box></app-chat-input-box>
</main>














